<template>
    <div class="editStream">

        <Loading v-if="!stream"></Loading>

        <template v-if="stream">

            <EditStreamOfferDetails></EditStreamOfferDetails>

            <Card
                addclass="mb-30 pt-10 pb-30"
                :removePB="true">
                <FormInput
                    :label="$lng.__('lk_webmaster_streams_crud', 'Stream name')"
                    :placeholder="$lng.__('lk_webmaster_streams_crud', 'Enter a stream name')"
                    :tooltip="$lng.__('lk_webmaster_streams_crud', 'Enter unique name for this stream')"
                    v-model="stream.stream_name"
                    :validations="[
                    {
                        rule: validations.isStringLengthBiggerThan(stream.stream_name, 1),
                        message: 'Minimum 2 symbols'
                    },
                    {
                        rule: validations.isStringLengthSmallThan(stream.stream_name, 255),
                        message: 'Stream name must be small than 255 symbols'
                    },
                ]"
                ></FormInput>
            </Card>

            <EditStreamSelectGeo
                v-if="$store.state.webmaster.offer"
                @selected="setGeo"
            ></EditStreamSelectGeo>
            <Loading v-else></Loading>

            <Loading v-if="isLoadingLandings && $store.state.webmaster.offer"></Loading>

            <template v-if="isLoadingEditStreamSelectLanding">
                <EditStreamSelectLanding
                    :title="$lng.__('lk_webmaster_streams_crud', 'Select Landing page')"
                    :selected="parseInt(stream.landing_id)"
                    :landings="landings"
                    @select="setLanding"
                    id="selectLanding"
                    key="selectLanding">
                </EditStreamSelectLanding>

                <EditStreamSelectLanding
                    v-if="prelandings.length"
                    :title="$lng.__('lk_webmaster_streams_crud', 'Select Prelanding page')"
                    :selected="parseInt(stream.prelanding_id)"
                    :landings="prelandings"
                    @select="setPrelanding"
                    id="selectPrelanding"
                    key="selectPrelanding">
                </EditStreamSelectLanding>
            </template>

            <Card>
                <h2>{{ $lng.__('lk_webmaster_streams_crud', 'Create stream') }}</h2>

                <template v-if="domainOptions.length">

                    <h3 class="pb-10">{{ $lng.__('lk_webmaster_streams_crud', 'Domains') }}</h3>

                    <div class="grid">
                        <div class="row">
                            <div class="c-md-6">
                                <FormSelect
                                    :label="$lng.__('lk_webmaster_streams_crud', 'Landing domain')"
                                    :placeholder="$lng.__('lk_webmaster_streams_crud', 'Use default domain')"
                                    v-model:selected="stream.domain_landing"
                                    :options="domainOptions"
                                ></FormSelect>
                            </div>
                            <div v-if="stream.prelanding_id !== null" class="c-md-6">
                                <FormSelect
                                    :label="$lng.__('lk_webmaster_streams_crud', 'Prelanding domain')"
                                    :placeholder="$lng.__('lk_webmaster_streams_crud', 'Use default domain')"
                                    v-model:selected="stream.domain_prelanding"
                                    :options="domainOptions"
                                ></FormSelect>
                            </div>
                        </div>
                    </div>
                </template>



                <h3 class="pt-30">{{ $lng.__('lk_webmaster_streams_crud', 'Counters and metrics') }}</h3>

                <div class="grid">
                    <div class="row pt-10">
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Facebook ID')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Facebook ID')"
                                v-model="stream.facebook_id"
                                :withIcon="true"
                                iconId="facebookLogoColor"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.facebook_id, 20),
                                    message: 'Facebook ID must be less than 20 symbols'
                                },
                                {
                                    rule: validations.isNumberValid(stream.facebook_id),
                                    message: 'Facebook ID must be a number'
                                },
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormSelect
                                :label="$lng.__('lk_webmaster_streams_crud', 'Facebook Track')"
                                v-model:selected="stream.facebook_track"
                                :options="facebookTrackOptions"
                            ></FormSelect>
                        </div>

                        <div class="c-md-12 pb-50">
                            <div class="editStream__checkbox">
                                <div
                                    class="editStream__checkbox__item"
                                    @click="stream.display_on_landing=!stream.display_on_landing">
                                    <FormRadio
                                        :size="20"
                                        :checked="stream.display_on_landing"
                                    ></FormRadio>
                                    {{ $lng.__('lk_webmaster_streams_crud', 'Display on landing') }}
                                </div>
                                <div
                                    class="editStream__checkbox__item"
                                    @click="stream.display_on_prelanding=!stream.display_on_prelanding">
                                    <FormRadio
                                        :size="20"
                                        :checked="stream.display_on_prelanding"
                                    ></FormRadio>
                                    {{ $lng.__('lk_webmaster_streams_crud', 'Display on prelanding') }}
                                </div>
                                <div
                                    class="editStream__checkbox__item"
                                    @click="stream.display_on_success=!stream.display_on_success">
                                    <FormRadio
                                        :size="20"
                                        :checked="stream.display_on_success"
                                    ></FormRadio>
                                    {{ $lng.__('lk_webmaster_streams_crud', 'Display on success.html') }}
                                </div>
                            </div>

                        </div>

                        <div class="c-md-6 pb-40">
                            <FormInput
                                label="Facebook 2nd ID (only success.html)"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Facebook 2nd ID')"
                                v-model="stream.facebook_id2"
                                :withIcon="true"
                                iconId="facebookLogoColor"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.facebook_id2, 20),
                                    message: 'Facebook 2nd ID must be less than 20 symbols'
                                },
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormSelect
                                label="Facebook-2 Track"
                                v-model:selected="stream.facebook_track2"
                                :options="facebookTrackOptions"
                            ></FormSelect>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Google Analytics ID')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Google Analytics ID')"
                                v-model="stream.google_a_id"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.google_a_id, 255),
                                    message: 'Google Analytics ID must be less than 255 symbols'
                                },
                            ]"
                                :withIcon="true"
                                iconId="gaLogoColor"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Google Tag Manager ID')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Google Tag Manager ID')"
                                v-model="stream.google_gtag_id"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.google_gtag_id, 255),
                                    message: 'Google Tag Manager ID must be less than 255 symbols'
                                },
                            ]"
                                :withIcon="true"
                                iconId="gtagLogoColor"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Yandex Metrika ID')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Yandex Metrika ID')"
                                :withIcon="true"
                                iconId="yandexMetricLogoColor"
                                v-model="stream.yandex_m_id"
                                :validations="[
                                    {
                                        rule: validations.isNumberValid(stream.yandex_m_id),
                                        message: 'Yandex Metrika ID must be a number'
                                    },
                                    {
                                        rule: validations.isStringLengthSmallThan(stream.yandex_m_id, 21),
                                        message: 'Yandex Metrika ID must be less than 21 symbol'
                                    },
                                ]"
                            ></FormInput>
                        </div>
                        <div v-if="stream.tt_id !== undefined" class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'TikTok Pixel ID')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'TikTok Pixel ID')"
                                :withIcon="true"
                                iconId="tikTokLogo"
                                v-model="stream.tt_id"
                                :validations="[
                                    {
                                        rule: $validations.isStringLengthSmallThan(stream.tt_id, 21),
                                        message: 'TikTok Pixel ID must be less than 21 symbol'
                                    },
                                    {
                                        rule: $validations.isOnlyLettersAndNumbers(stream.tt_id),
                                        message: 'Incorrect TikTok Pixel ID'
                                    },
                                ]"
                            ></FormInput>
                        </div>
                    </div>
                </div>

                <h3>{{ $lng.__('lk_webmaster_streams_crud', 'Subaccounts') }}</h3>

                <div class="grid">
                    <div class="row pt-10">
                        <div class="c-md-6 pb-40">
                            <FormInput
                                label="Utm Medium"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Utm Medium')"
                                v-model="stream.utm_medium"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.utm_medium, 255),
                                    message: 'Utm Medium must be less than 255 symbols'
                                },
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                label="Utm Campaign"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Utm Campaign')"
                                v-model="stream.utm_campaign"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.utm_campaign, 255),
                                    message: 'Utm Campaign must be less than 255 symbols'
                                },
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                label="Utm Content"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Utm Content')"
                                v-model="stream.utm_content"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.utm_content, 255),
                                    message: 'Utm Content must be less than 255 symbols'
                                },
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                label="Utm Term"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Utm Term')"
                                v-model="stream.utm_term"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.utm_term, 255),
                                    message: 'Utm Term must be less than 255 symbols'
                                },
                            ]"
                            ></FormInput>
                        </div>
                    </div>
                </div>

                <h3>{{ $lng.__('lk_webmaster_streams_crud', 'Postback') }}</h3>

                <div class="grid">
                    <div class="row pt-10">
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Lead Creation')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Lead Creation')"
                                v-model="stream.lead_creation"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.lead_creation, 255),
                                    message: 'Lead Creation must be less than 255 symbols'
                                },
                                {
                                    rule: validations.isURLNameValid(stream.lead_creation),
                                    message: $lng.__('lk_webmaster_streams_crud', 'Lead creation postback url is incorrect')
                                }
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Lead Confirmation')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Lead Confirmation')"
                                v-model="stream.lead_confirmation"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.lead_confirmation, 255),
                                    message: 'Lead Confirmation must be less than 255 symbols'
                                },
                                {
                                    rule: validations.isURLNameValid(stream.lead_confirmation),
                                    message: $lng.__('lk_webmaster_streams_crud', 'Lead confirmation postback url is incorrect')
                                }
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Lead Rejection')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Lead Rejection')"
                                v-model="stream.lead_rejection"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.lead_rejection, 255),
                                    message: 'Lead Rejection must be less than 255 symbols'
                                },
                                {
                                    rule: validations.isURLNameValid(stream.lead_rejection),
                                    message: $lng.__('lk_webmaster_streams_crud', 'Lead rejection postback url is incorrect')
                                }
                            ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 pb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_streams_crud', 'Sending Lead in Trash')"
                                :placeholder="$lng.__('lk_webmaster_streams_crud', 'Please enter')+' '+$lng.__('lk_webmaster_streams_crud', 'Sending Lead in Trash')"
                                v-model="stream.lead_trash"
                                :validations="[
                                {
                                    rule: validations.isStringLengthSmallThan(stream.lead_trash, 255),
                                    message: 'Lead Trash must be less than 255 symbols'
                                },
                                {
                                    rule: validations.isURLNameValid(stream.lead_trash),
                                    message: $lng.__('lk_webmaster_streams_crud', 'Lead trash postback url is incorrect')
                                }
                            ]"
                            ></FormInput>
                        </div>
                    </div>
                </div>


                <!-- Витрины -->
                <template v-if="stream.geo==='IT'">
                    <h3 class="flex-row-center">
                        {{ $lng.__('lk_webmaster_streams', 'Showcase') }}
                        <div
                            @click="clickShowcaseInstructionLink()"
                            class="wv-hlp"
                        ></div>
                    </h3>
                    <div class="grid">
                        <div class="row pb-40">
                            <div class="c-md-6">
                                <FormSwitch
                                    :label="$lng.__('lk_webmaster_streams', 'Show on Success Page')"
                                    v-model:checked="stream.shopwindow_active.success"
                                ></FormSwitch>
                            </div>
                            <div class="c-md-6" v-if="stream.prelanding_id">
                                <FormSwitch
                                    :label="$lng.__('lk_webmaster_streams', 'Show on Comebacker')"
                                    v-model:checked="stream.shopwindow_active.comebacker"
                                ></FormSwitch>
                            </div>
                        </div>
                    </div>
                </template>




                <Alert
                    v-if="hasValidationErrors"
                    addclass="mb-30">
                    <div class="mb-5">
                        <strong>{{ $lng.__('lk_webmaster_streams_crud', 'You need to fullfill required fields')+':' }}</strong>
                    </div>
                    <p v-for="error in validationErrors">
                        - {{ $lng.__('lk_webmaster_streams_crud', error) }}
                    </p>

                </Alert>

                <div class="editStream__btns">

                    <div class="editStream__btns-action">
                        <button
                            @click="createStream()"
                            v-if="isStreamCreating && !hasValidationErrors"
                            class="wv-btn--green-active wv-btn--text">
                            {{ $lng.__('lk_webmaster_streams_crud', 'Create stream') }}
                        </button>
                        <button
                            @click="saveStream()"
                            v-if="isStreamEditing && !hasValidationErrors"
                            class="wv-btn--green-active wv-btn--text">
                            {{ $lng.__('lk_webmaster_streams_crud', 'Save stream') }}
                        </button>
                        <button
                            @click="showDiscardModal=true"
                            v-if="isStreamCreating"
                            class="wv-btn--gray wv-btn--text">
                            {{ $lng.__('lk_webmaster_streams_crud', 'Discard') }}
                        </button>
                        <button
                            v-if="hasValidationErrors"
                            class="wv-btn--gray wv-btn--text disabled">
                            {{ $lng.__('lk_webmaster_streams_crud', 'Save stream') }}
                        </button>
                    </div>

                </div>

            </Card>

            <div class="editStreamNotice">
                <div class="editStreamNotice__icon">
                    <SvgUse :width="20" :height="20" id="information"></SvgUse>
                </div>
                <div class="editStreamNotice__content">
                    <p>{{ $lng.__('lk_webmaster_streams_crud', 'Please note, the listed webpages are not the property of Webvork LLP') }}</p>
                    <p>{{ $lng.__('lk_webmaster_streams_crud', 'Their administration and their owners compliance with applicable law is not the responsibility of Webvork LLP.') }}</p>
                    <p>{{ $lng.__('lk_webmaster_streams_crud', 'If you have any problems with the landing pages, please report them to email info@webvork.com.') }}</p>
                </div>
            </div>

            <Modal
                title="Discard stream"
                v-if="showDiscardModal"
                @close="showDiscardModal=false">
                <div class="pt-20 text-center">
                    <p class="pb-30">
                        Cancel stream creation? The data entered will not be saved.
                    </p>
                    <div class="wv-btns wv-btns-center">
                        <button
                            class="wv-btn--gray wv-btn--text"
                            @click="$router.push('/'+$route.params.lng+'/webmaster/offers')">
                            Yes
                        </button>
                        <button
                            class="wv-btn--gray wv-btn--text"
                            @click="showDiscardModal=false">
                            No
                        </button>
                    </div>
                </div>
            </Modal>

        </template>

    </div>
</template>

<script>

import EditStreamSelectLanding from './EditStreamSelectLanding.vue';
import EditStreamSelectGeo from './EditStreamSelectGeo.vue';
import EditStreamOfferDetails from './EditStreamOfferDetails.vue';

export default {
    name: 'EditStream',
    props: [],
    data() {
        return {
            isLoadingLandings: false,
            isLoadingEditStreamSelectLanding: false,
            facebookTrackOptions: [
                {name: 'PageView', id: 'PageView'},
                {name: 'ViewContent', id: 'ViewContent'},
                {name: 'Purchase', id: 'Purchase'},
            ],
            showDiscardModal: false,
            validationErrors: [],
        };
    },
    computed: {
        stream: {
            get() {
                return this.$store.state.webmaster.stream;
            },
            set(stream) {
                this.$store.commit('webmaster/setStream', stream);
            }
        },
        prelandings() {
            return this.$store.state.webmaster.prelandings.filter(item => {
                return item.is_actual || item.is_top || item.is_archived;
            });
        },
        landings() {
            return this.$store.state.webmaster.landings.filter(item => {
                return item.is_actual || item.is_top || item.is_archived;
            });
        },
        hasValidationErrors() {
            this.checkValidationErrors();
            return this.validationErrors.length > 0;
        },
        streamPreparedToRequest() {
            return {
                ...this.stream,
                domain_landing: (this.stream.domain_landing === false || this.stream.domain_landing === null) ? '' : this.stream.domain_landing,
                domain_prelanding: (this.stream.domain_prelanding === false || this.stream.domain_prelanding === null) ? '' : this.stream.domain_prelanding,
            }
        },
        domainOptions() {
            return collect(this.$store.state.webmaster.domains)
                .filter(domain => {
                    return domain.status === 'Connected' || domain.status === 'Parked'
                })
                .map(domain => {
                    return {
                        id: domain.url,
                        name: domain.url
                    };
                })
                .all();
        },
        isStreamCreating() {
            return this.streamId === 0;
        },
        isStreamEditing() {
            return this.streamId > 0;
        },
        streamId() {
            return this.$route.name === 'webmaster.streams.create' ? 0 : parseInt(this.$route.params.stream_id);
        },
        offerId() {
            return this.$route.params.offer_id || parseInt(this.stream.offer_id);
        },
        validations() {
            return WV.validations;
        },
        showcaseInstructionLink() {
            let path = '';
            let language = document.location.pathname.split('/')[1];
            switch (language) {
                case 'ru':
                    path = 'https://webvorkltd.zendesk.com/hc/ru/articles/6524454893585-%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%B4%D0%BB%D1%8F-%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D0%B8%D1%82%D1%80%D0%B8%D0%BD%D1%8B-%EF%BB%BF%EF%BB%BF%EF%BB%BF';
                    break;
                case 'en':
                    path = 'https://webvorkltd.zendesk.com/hc/ru/articles/6529576995857-Instructions-for-connecting-the-Showcase-';
                    break;
                case 'it':
                    path = 'https://webvorkltd.zendesk.com/hc/it/articles/6545410989073-Istruzioni-per-l-attivazione-della-Vetrina-';
                    break;
                case 'de':
                    path = 'https://webvorkltd.zendesk.com/hc/de/articles/6545546149009-Anleitung-zum-Anschlie%C3%9Fen-des-Schaufensters-';
                    break;
                case 'es':
                    path = 'https://webvorkltd.zendesk.com/hc/es/articles/6545451210257-Instrucciones-para-conectar-la-Vitrina-';
                    break;
                default:
                    path = `'https://webvorkltd.zendesk.com/hc/ru/articles/6529576995857-Instructions-for-connecting-the-Showcase-'`;
                    break;
            }

            return path;
        },
    },
    methods: {
        checkValidationErrors() {
            this.validationErrors = [];

            if (!this.$validations.isStringLengthBiggerThan(this.stream.stream_name, 1)) {
                this.validationErrors.push(this.$lng.__('lk_webmaster_errors', 'Enter a stream name'));
            }
            if (!this.$validations.isStringNotEmpty(this.stream.geo)) {
                this.validationErrors.push('Select geo');
            }
            if (!this.$validations.isStringNotEmpty(this.stream.landing_id)) {
                this.validationErrors.push('Select Landing page');
            }
            if(this.stream.lead_creation.length > 0){
                 if (!this.$validations.isURLNameValid(this.stream.lead_creation)) {
                    this.validationErrors.push(this.$lng.__('lk_webmaster_streams_crud', 'Lead creation postback url is incorrect'));
                }
            }
            if(this.stream.lead_confirmation.length > 0){
                if (!this.$validations.isURLNameValid(this.stream.lead_confirmation)) {
                    this.validationErrors.push(this.$lng.__('lk_webmaster_streams_crud', 'Lead confirmation postback url is incorrect'));
                }
            }
           if(this.stream.lead_rejection.length > 0){
                if (!this.$validations.isURLNameValid(this.stream.lead_rejection)) {
                    this.validationErrors.push(this.$lng.__('lk_webmaster_streams_crud', 'Lead rejection postback url is incorrect'));
                }
           }
           if(this.stream.lead_trash.length > 0){
                if (!this.$validations.isURLNameValid(this.stream.lead_trash)) {
                    this.validationErrors.push(this.$lng.__('lk_webmaster_streams_crud', 'Lead trash postback url is incorrect'));
                }
           }
        },
        updateField(field, value) {
            this.stream[field] = value;
            this.$store.commit('webmaster/setStream', this.stream);
        },
        saveStream() {
            this.$store.dispatch('webmaster/saveStream', this.streamPreparedToRequest)
                .then(() => {
                    this.$router.push('/' + this.$route.params.lng + '/webmaster/streams');
                });
        },
        createStream() {
            delete this.stream.stream_id;
            this.$store.dispatch('webmaster/createStream', this.stream)
                .then(response => {
                    this.$router.push('/' + this.$route.params.lng + '/webmaster/streams/view/' + response.stream_id);
                });
        },
        setGeo(geo) {
            this.$store.commit('webmaster/setStream', {
                ...this.stream,
                geo: geo.code,
                landing_id: null,
                prelanding_id: null,
            });
            this.getLandingsPrelandings(geo);
        },
        getLandingsPrelandings(geo) {
            this.isLoadingLandings = true;
            this.isLoadingEditStreamSelectLanding = false;
            Promise.all([
                    this.$store.dispatch('webmaster/getPrelandings', {offer_id: this.stream.offer_id, geo: this.stream.geo}),
                    this.$store.dispatch('webmaster/getLandings', {offer_id: this.stream.offer_id, geo: this.stream.geo})
                ])
                .then(() => {
                    this.isLoadingLandings = false;
                    if ((this.$store.state.webmaster.landings.length >= 1) || (this.$store.state.webmaster.prelandings.length >= 1) || geo) {
                        this.isLoadingEditStreamSelectLanding = true;
                    }
                });
        },
        setPrelanding(prelanding) {
            if (this.stream.prelanding_id === prelanding.id) {
                this.stream.prelanding_id = null;
                this.stream.domain_prelanding = null;
                this.stream.shopwindow_active.comebacker = false;
            } else {
                this.stream = {...this.stream, prelanding_id: prelanding.id};
            }
        },
        setLanding(landing) {
            this.stream = {...this.stream, landing_id: landing.id};
        },
        clickShowcaseInstructionLink() {
            window.open(this.showcaseInstructionLink,'_blank');
        },
    },
    beforeMount() {
        this.$store.dispatch('webmaster/getDomains');
        this.$store.dispatch('webmaster/getStream', {
                stream_id: this.streamId,
                offer_id: this.offerId
            })
            .then(stream => {
                this.$store.dispatch('webmaster/getOffer', this.stream.offer_id);
                this.getLandingsPrelandings();
            });
    },
    beforeUnmount() {
        this.$store.commit('webmaster/clean', {
            offer: false,
            stream: false,
            landings: [],
            prelandings: [],
        });
    },
    components: {
        EditStreamSelectLanding,
        EditStreamSelectGeo,
        EditStreamOfferDetails,
    }
};
</script>

<style scoped lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.editStream {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }

    h2 {
        margin-bottom: 40px;
    }

    h3 {
        margin: 20px 0 30px;
    }

    &__btns {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        @include break(sm){
            justify-content: flex-end;
        }
        &-discard {
            width: 50%;
        }
        &-action {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include break(sm) {
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
            }
            button{
                margin-bottom: 10px;
                width: 100%;
                @include break(sm) {
                    width: auto;
                    margin-bottom: 0;
                    margin-left: 20px;
                }
            }
        }
    }
    &__checkbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: -20px;
        @include break(lg) {
            align-items: center;
            flex-direction: row;
        }
        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-right: 40px;
            font-size: 14px;
            font-family: $font-secondary;
            cursor: pointer;
            margin-bottom: 10px;
            @include break(lg) {
                margin-bottom: 0;
            }
            & > div:first-child {
                margin-right: 10px;
            }
        }
    }
}

.editStreamNotice {
    margin-top: 30px;
    background: $color-day-line;
    padding: 25px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 30px;
        opacity: .5;
    }

    &__content {
        p {
            color: $color-day-dark-gray;
            font-size: 14px;
            line-height: 16px;
            padding-bottom: 3px;
        }
        a {
            text-decoration: underline;
        }
    }
}
</style>
